/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Aladin";
  src: url('Aladin-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "DSA-Akademiesiegel";
  src: url('Akademiesiegel.ttf') format("truetype");
}
@font-face {
  font-family: "Homemade Apple";
  src: url('HomemadeApple-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Zeyada";
  src: url('Zeyada-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Caveat";
  src: url('Caveat-VariableFont_wght.ttf') format("truetype");
}
@font-face {
  font-family: "Mynerve";
  src: url('Mynerve-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Nothing You Could Do";
  src: url('NothingYouCouldDo-Regular.ttf') format("truetype");
}
@media (max-aspect-ratio: 5/3) {
  select {
    color: #724e27;
  }
}
* {
  box-sizing: border-box;
  font-family: "Aladin", cursive;
  touch-action: manipulation;
}
body, html {
  margin: 0;
  padding: 0;
  font-size: 15px;
  background-color: #f2e6d9;
}
div#preload {
  display: none;
}
h10 {
  height: 80px;
}